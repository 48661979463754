
<template>
    <component :is="'div'">

        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="noticeData === undefined"
        >
            <h4 class="alert-heading">Error al obtener los datos de la noticia</h4>
            <div class="alert-body">
                No se encontró ninguna noticia con este id. Verificar en la
                <b-link
                    class="alert-link"
                    :to="{ name: 'notices'}"
                >
                    Lista de noticias
                </b-link>
                para buscar otra noticia
            </div>
        </b-alert>

        <b-tabs
            v-if="noticeData"
            pills
        >
      
            <!-- Tab: Information -->
            <b-tab>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Información</span>
                </template>
                <notice-edit-tab-information
                    class="mt-2 pt-75"
                    :notice-data="noticeData"
                />
            </b-tab>
      
        </b-tabs>
    </component>
</template>

<script>

    import NoticeEditTabInformation from './NoticeEditTabInformation.vue';
    import {onUnmounted, ref} from '@vue/composition-api';
    import noticeStoreModule from '../noticeStoreModule';
    import router from '@/router';
    import store from '@/store';

    export default {
        components: {
            NoticeEditTabInformation
        },
        setup() {
            const noticeData = ref(null);

            const NOTICE_APP_STORE_MODULE_NAME = 'app-notice';

            // Register module
            if (!store.hasModule(NOTICE_APP_STORE_MODULE_NAME)) store.registerModule(NOTICE_APP_STORE_MODULE_NAME, noticeStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(NOTICE_APP_STORE_MODULE_NAME)) store.unregisterModule(NOTICE_APP_STORE_MODULE_NAME)
            });

            store.dispatch('app-notice/fetchNotice', { id: router.currentRoute.params.id })
                .then(response => {
                    if (response.data === 'error') {
                        noticeData.value = undefined
                    } else {
                        noticeData.value = response.data;
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        noticeData.value = undefined
                    }
                });

            return {
                noticeData
            }
        }
    }

</script>