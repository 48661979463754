import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchNotices(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/notices', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchNotice(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/notice/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        createNotice(ctx, { payload }) {

            let formData = new FormData();
                
            formData.append('image', payload.image);

            Object.entries(payload).map(item => 
            {
                if (item[0] !== 'image') formData.append(item[0], item[1]);
            });

            return new Promise((resolve, reject) => {
                axios
                    .post('/notice/create', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateNotice(ctx, { id, payload }) {

            let formData = new FormData();
                
            formData.append('image', payload.image);

            Object.entries(payload).map(item => 
            {
                if (item[0] !== 'image') formData.append(item[0], item[1]);
            });

            return new Promise((resolve, reject) => {
                axios
                    .put(`/notice/${id}/update`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        desactivateNotice(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/notice/${id}/desactivate`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        activateNotice(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/notice/${id}/activate`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteNotice(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/notice/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}